import React from "react";
import { graphql } from "gatsby";
import { Link } from "@intractive/gatsby-plugin-react-intl";
import * as PropTypes from "prop-types";
import Helmet from "react-helmet";
import Layout from "../../Layout";

const style = require("../index.module.css");

const propTypes = {
  data: PropTypes.object.isRequired,
}

class SubpageTemplate extends React.Component {
  render() {
    const page = this.props.data.pageData
    const parent = this.props.data.parentData

    const {
      metaDescription,
      pageTitle,
      text,
    } = page

    return (
      <Layout>
        <Helmet>
          <title>{pageTitle} · STIP</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <div className="row main-content">
          <div className="large-3 medium-3 columns sidebar hide-for-small">
            <div className="nav-sub">
              <ul>
                  <li><Link to={`/${parent.slug}/`}>{parent.pageTitle}</Link>
                  <ul>
                    {parent.subpages && parent.subpages.map(function(subpage, index){
                      return (
                        <li key={index}><Link to={`/${parent.slug}/${subpage.slug}/`} activeClassName={style.active}>{subpage.pageTitle}</Link></li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="large-9 medium-9 columns">
            <section>
              <h1>{pageTitle}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: text.childMarkdownRemark.html,
                }}
              />
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

SubpageTemplate.propTypes = propTypes

export default SubpageTemplate

export const pageQuery = graphql`
  query($slug: String! $parent: String! $node_locale: String) {
    pageData: contentfulSubpage(slug: { eq: $slug }, node_locale: { eq: $node_locale }) {
      metaDescription
      pageTitle
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    parentData: contentfulPage(slug: { eq: $parent }, node_locale: { eq: $node_locale }) {
      pageTitle
      slug
      subpages {
        slug
        pageTitle
      }
    }
  }
`
